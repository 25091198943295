import React, { useState } from 'react';
import SectionWrapper, {
  ButtonGroup,
  ContactFormOverlay,
  ContentArea,
  DonateButton,
  DonationForm,
  Heading,
} from './donateSection.style';

import Box from 'common/src/components/Box';
import CheckBox from 'common/src/components/Checkbox';
import Container from 'common/src/components/UI/Container';
import { DataContext } from 'common/src/contexts/DataContext'
import Image from 'common/src/components/Image';
import { Input } from 'common/src/components/InputGroup/inputGroup.style.js';
import LoaderSVG from 'common/src/assets/image/lang4u/dual-ring.svg';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import Text from 'common/src/components/Text';
import enterImage from 'common/src/assets/image/lang4u/enter.svg';
import { graphql } from 'gatsby';
import { useContactFormData } from '../../../utils/useContactFormData'

export const fragment = graphql`
  fragment ContactContent on PRISMIC_Landing_page {
    contact_form_title
    contact_form_subtitle
  }
`

const DonateSection = ({ row, col, inputWrapper, colCentered }) => {
  const { data } = React.useContext(DataContext)
  const formData = useContactFormData()
  const $form = React.useRef()

  const [state, setState] = useState({
    name: '',
    email: '',
    policy: false,
    terms: false,
    clicked: false
  });

  const [isLoading, setLoading] = React.useState(false)

  const submitContactForm = () => {
    setLoading(true)
    $form.current.submit()
  }

  const handleFormData = React.useCallback(
    (value, name) => {
      setState({
        ...state,
        [name]: value,
      });
    },
    [state]
  );

  const handleDonation = e => {
    e.preventDefault();

    setState({
      ...state,
      clicked: true
    });

    if (state.name.trim().length > 2 && /^.+@.+\..+$/.test(state.email.trim()) && state.policy && state.terms) {
      submitContactForm()
    }
  };

  if (!data) return null;

  const needTerms = state.clicked && (!state.terms || !state.policy)
  const validName = !state.clicked || state.name.trim().length > 2
  const validEmail = !state.clicked || /^.+@.+\..+$/.test(state.email.trim())

  const errorStyles = { borderColor: 'red' }

  return (
    <SectionWrapper id="contacts">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box
            className="col"
            {...colCentered}
            style={{ alignItems: 'center' }}
          >
            <ContentArea>
              <RichText
                as="div"
                render={data.contact_form_title}
                Component={Heading}
              />
              <Text content={RichText.asText(data.contact_form_subtitle)} />
              <ButtonGroup>
                {/*<a href="#1" className="learn__more-btn alt">
                  <span className="hyphen" />
                  <span className="btn_text">Заполнить анкету</span>
                </a>
                <Text content="или" />
                <a href="#1" className="learn__more-btn">
                  <span className="hyphen" />
                  <span className="btn_text">Детали нашей программы</span>
                </a>*/}
              </ButtonGroup>
            </ContentArea>
          </Box>

          <Box className="col" {...col}>
            <DonationForm
              onSubmit={e => handleDonation(e)}
              method="post"
              data-netlify="true"
              data-netlify-honeypot="lang4u-valid-customer"
              ref={$form}
              noValidate
              name="contact"
            >
              <input type="hidden" name="form-name" value="contact" />
              {isLoading ? (
                <ContactFormOverlay>
                  <Image src={LoaderSVG} alt="Loader" />
                </ContactFormOverlay>
              ) : null}
              <Box {...inputWrapper}>
                <Input
                  type="text"
                  name="name"
                  value={state.name}
                  placeholder={formData.nameLabel}
                  onChange={e => handleFormData(e.target.value, 'name')}
                  required={true}
                  aria-label="input"
                  style={validName ? {} : errorStyles}
                />
              </Box>
              <Box {...inputWrapper}>
                <Input
                  type="email"
                  name="email"
                  value={state.email}
                  placeholder={formData.emailLabel}
                  onChange={e => handleFormData(e.target.value, 'email')}
                  required={true}
                  aria-label="email"
                  style={validEmail ? {} : errorStyles}
                />
              </Box>
              <Box style={{ marginLeft: '8px', marginBottom: '16px' }}>
                <CheckBox
                  labelText={
                    <React.Fragment>
                      {formData.serviceTermsPrefix} <a href={formData.termsHref} target="_blank">{formData.serviceTermsLink}</a>
                    </React.Fragment>
                  }
                  checked={state.terms}
                  onChange={e => handleFormData(e.target.checked, 'terms')}
                />
              </Box>
              <Box style={{ marginLeft: '8px' }}>
                <CheckBox
                  labelText={
                    <React.Fragment>
                      {formData.personalDataPrefix} <a href={formData.privacyHref} target="_blank">{formData.personalDataLink}</a>
                    </React.Fragment>
                  }
                  checked={state.policy}
                  onChange={e => handleFormData(e.target.checked, 'policy')}
                />
              </Box>
              {needTerms ? (
                <Box style={{ marginLeft: '8px', marginTop: '8px', color: 'red' }}>
                  {formData.mustAccept}
                </Box>
              ) : null}
              <DonateButton type="submit">
                {formData.buttonText + ' '}
                <Image width="25px" src={enterImage} alt={formData.buttonText} />
              </DonateButton>
            </DonationForm>
          </Box>
        </Box>
      </Container>
    </SectionWrapper>
  );
};

// DonateSection style props
DonateSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  textStyle: PropTypes.object,
};

// DonateSection default style
DonateSection.defaultProps = {
  // DonateSection row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // DonateSection col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
  colCentered: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
    display: 'flex',
  },
  inputWrapper: {
    mb: '30px',
  },
};

export default DonateSection;
