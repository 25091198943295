import { ContentWrapper, GlobalStyle } from '../containers/Lang4U/lang4u.style';
import React, { Fragment } from 'react';

import BannerSection from '../containers/Lang4U/Banner';
import { DataContext } from 'common/src/contexts/DataContext'
import DonateSection from '../containers/Lang4U/DonateSection';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import FaqSection from '../containers/Lang4U/Faq';
import FeatureSection from '../containers/Lang4U/Feature';
import Footer from '../containers/Lang4U/Footer';
import InfoSection from '../containers/Lang4U/Info';
import Navbar from '../containers/Lang4U/Navbar';
import PartnerSection from '../containers/Lang4U/Partner';
import PricingSection from '../containers/Lang4U/Pricing';
import { ResetCSS } from 'common/src/assets/css/style';
import { RichText } from 'prismic-reactjs';
import SEO from '../components/seo';
import Sticky from 'react-stickynode';
import TestimonialSection from '../containers/Lang4U/Testimonial';
import { ThemeProvider } from 'styled-components';
import TrialSection from '../containers/Lang4U/Trial';
import WorkingProcessSection from '../containers/Lang4U/WorkingProcess';
import { graphql } from 'gatsby';
import { lang4uTheme } from 'common/src/theme/lang4u';

export const queryFragment = graphql`
  fragment IndexPageContent on PRISMIC_Landing_page {
    document_seo_title
    document_seo_description
    ...BannerContent
    ...FaqContent
    ...FeatureContent
    ...InfoContent
    ...NavbarContent
    ...PartnerContent
    ...PricingContent
    ...TestimonialsContent
    ...TrialContent
    ...WorkingProcessContent
    ...ContactContent
  }
`

export const IndexPage = result => {
  const edge = result.prismic.allLanding_pages.edges.slice(0, 1).pop();
  if (!edge) {
    return null;
  }
  const data = edge.node;
  if (!data) {
    return null;
  }

  return (
    <DataContext.Provider value={{ data }}>
      <ThemeProvider theme={lang4uTheme}>
        <Fragment>
          <SEO
            title={RichText.asText(data.document_seo_title)}
            description={RichText.asText(data.document_seo_description)}
            image={data.hero_image.url}
            imageDimensions={data.hero_image.dimensions}
          />

          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            <BannerSection />
            <WorkingProcessSection />
            <InfoSection />
            <FeatureSection />
            <DonateSection />
            <PricingSection />
            <PartnerSection />
            <TestimonialSection />
            <FaqSection />
            <TrialSection />
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    </DataContext.Provider>
  );
};
