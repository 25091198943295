import Box from 'common/src/components/Box';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import Container from 'common/src/components/UI/Container';
import { DataContext } from 'common/src/contexts/DataContext'
import Fade from 'react-reveal/Fade';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import ImageTwo from 'common/src/assets/image/lang4u/certificate.svg';
import InfoSectionWrapper from './info.style';
import PrismicImage from 'common/src/components/PrismicImage';
import PropTypes from 'prop-types';
import React from 'react';
import { RichText } from 'prismic-reactjs';
import Text from 'common/src/components/Text';
import { graphql } from 'gatsby';

export const fragment = graphql`
  fragment InfoContent on PRISMIC_Landing_page {
    partnership_title
    partnership_text
    partnership_image
    partnership_button_text
    partnership_button_link
  }
`

const InfoSection = ({
  row,
  col,
  title,
  description,
  button,
  textArea,
  imageArea,
  imageAreaRow,
  imageWrapper,
  imageWrapperOne,
  imageWrapperTwo,
  textAreaRow,
  image1,
}) => {
  const { data } = React.useContext(DataContext)
  if (!data) return null;

  const ctaText = RichText.asText(data.partnership_button_text)
  const ctaLink = RichText.asText(data.partnership_button_link)

  return (
    <InfoSectionWrapper>
      <Container fullWidth noGutter className="info-sec-container">
        <Box {...row} {...imageAreaRow}>
          <Box {...col} {...imageArea} className="image_area">
            <Card {...imageWrapper} {...imageWrapperOne}>
              <Fade left>
                <PrismicImage {...image1} {...data.partnership_image} />
              </Fade>
            </Card>
            <Card {...imageWrapper} {...imageWrapperTwo}>
              <Fade bottom>
                <Image src={ImageTwo} width="180px" alt={RichText.asText(data.partnership_title)} alt="Certificate" />
              </Fade>
            </Card>
          </Box>
        </Box>
      </Container>
      <Container>
        <Box {...row} {...textAreaRow}>
          <Box {...col} {...textArea}>
            <Heading
              {...title}
              content={RichText.asText(data.partnership_title)}
            />
            <RichText
              as="div"
              render={data.partnership_text}
              Component={Text}
              {...description}
            />
            {ctaLink && ctaText ? (
              <Box>
                <a href={ctaLink}>
                  <Button {...button} title={ctaText} />
                </a>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Container>
    </InfoSectionWrapper>
  );
};

InfoSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
  imageAreaRow: PropTypes.object,
  imageWrapper: PropTypes.object,
  imageWrapperOne: PropTypes.object,
  imageWrapperTwo: PropTypes.object,
  textAreaRow: PropTypes.object,
};

InfoSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  textAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: ['100%', '100%', '50%', '50%', '42%'],
  },
  imageArea: {
    width: ['100%', '100%', '50%', '50%', '55%'],
    flexBox: true,
  },
  imageWrapper: {
    boxShadow: 'none',
  },
  imageWrapperOne: {
    mr: ['-250px', '-250px', '-200px', '-250px', '-400px'],
  },
  imageWrapperTwo: {
    alignSelf: 'flex-end',
    mb: ['-40px', '-40px', '-100px', '-60px', '-100px'],
    ml: ['0', '0', '70px', '70px', '120px'],
  },
  title: {
    fontSize: ['26px', '32px', '36px', '40px', '48px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '20px',
    lineHeight: '1.25',
  },
  description: {
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    color: '#343d48cc',
    lineHeight: '2',
    mb: '33px',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'secondaryWithBg',
  },
  image1: {
    height: 'auto',
    width: ['100%', '100%', '510px', '510px', '750px'],
  },
};

export default InfoSection
