import PricingTable, {
  ListItem,
  PricingButton,
  PricingButtonWrapper,
  PricingHead,
  PricingList,
  PricingPrice,
} from './pricing.style';
import React, { useState } from 'react';

import Box from 'common/src/components/Box';
import Button from 'common/src/components/Button';
import Container from 'common/src/components/UI/Container';
import { DataContext } from 'common/src/contexts/DataContext'
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import Heading from 'common/src/components/Heading';
import Icon from 'react-icons-kit';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import Text from 'common/src/components/Text';
import { checkmark } from 'react-icons-kit/icomoon/checkmark';
import { graphql } from 'gatsby';

export const fragment = graphql`
  fragment PricingContent on PRISMIC_Landing_page {
    pricing_group_one {
      button_text
      items1
      price
      price_description
      price_button_link
      text
      title
    }
    pricing_group_one_title
    pricing_group_two {
      button_text
      items1
      price
      price_button_link
      price_description
      text
      title
    }
    pricing_group_two_title
    pricing_subtitle
    pricing_title
  }
`

const PricingSection = ({
  sectionWrapper,
  row,
  secTitleWrapper,
  secHeading,
  secText,
  nameStyle,
  descriptionStyle,
  priceStyle,
  priceLabelStyle,
  buttonStyle,
  buttonFillStyle,
  listContentStyle,
}) => {
  const { data } = React.useContext(DataContext)

  const [state, setState] = useState(() => ({
    info: data.pricing_group_one,
    active: true,
  }));

  if (!data) return null;

  const { info } = state;
  const activeStatus = state.active;

  const pricingCarouselOptions = {
    type: 'slider',
    perView: 3,
    gap: 30,
    bound: true,
    breakpoints: {
      1199: {
        perView: 2,
        peek: {
          before: 100,
          after: 100,
        },
      },
      990: {
        perView: 1,
        peek: {
          before: 160,
          after: 160,
        },
      },
      767: {
        perView: 1,
        peek: {
          before: 80,
          after: 80,
        },
      },
      575: {
        perView: 1,
        gap: 15,
        peek: {
          before: 20,
          after: 20,
        },
      },
    },
  };

  return (
    <Box {...sectionWrapper} id="pricing">
      <Container>
        <Box {...secTitleWrapper}>
          <Text
            {...secText}
            content={RichText.asText(data.pricing_title)}
            style={{ textTransform: 'uppercase' }}
          />
          <Heading
            {...secHeading}
            content={RichText.asText(data.pricing_subtitle)}
          />
          <PricingButtonWrapper>
            <Button
              title={RichText.asText(data.pricing_group_one_title)}
              className={activeStatus ? 'active-item' : ''}
              onClick={() =>
                setState({
                  info: data.pricing_group_one,
                  active: true,
                })
              }
            />
            <Button
              title={RichText.asText(data.pricing_group_two_title)}
              className={activeStatus === false ? 'active-item' : ''}
              onClick={() =>
                setState({
                  info: data.pricing_group_two,
                  active: false,
                })
              }
            />
          </PricingButtonWrapper>
        </Box>
        <Box {...row}>
          <GlideCarousel
            carouselSelector="pricing-carousel"
            options={pricingCarouselOptions}
            controls={false}
          >
            <>
              {info.map((pricingTable, index) => (
                <GlideSlide key={`pricing-table-${index}`}>
                  <PricingTable freePlan={false} className="pricing_table">
                    <PricingHead>
                      <Heading
                        content={RichText.asText(pricingTable.title)}
                        {...nameStyle}
                      />
                      <Text
                        content={pricingTable.description}
                        {...descriptionStyle}
                      />
                    </PricingHead>
                    <PricingPrice>
                      <Text
                        content={RichText.asText(pricingTable.price)}
                        {...priceStyle}
                      />
                      <Text
                        content={RichText.asText(
                          pricingTable.price_description
                        )}
                        {...priceLabelStyle}
                      />
                    </PricingPrice>
                    <PricingButton>
                      <a href={RichText.asText(pricingTable.price_button_link)}>
                        {pricingTable.freePlan ? (
                          <Button
                            title={RichText.asText(pricingTable.button_text)}
                            {...buttonStyle}
                          />
                        ) : (
                          <Button
                            title={RichText.asText(pricingTable.button_text)}
                            {...buttonFillStyle}
                          />
                        )}
                      </a>
                    </PricingButton>
                    <PricingList>
                      {pricingTable.items1.map((subitem, subindex) => (
                        <ListItem key={`pricing-table-list-${subindex}`}>
                          <Icon
                            icon={checkmark}
                            className="price_list_icon"
                            size={13}
                          />
                          <Text content={subitem.text} {...listContentStyle} />
                        </ListItem>
                      ))}
                    </PricingList>
                  </PricingTable>
                </GlideSlide>
              ))}
            </>
          </GlideCarousel>
        </Box>
      </Container>
    </Box>
  );
};

PricingSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  nameStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  priceStyle: PropTypes.object,
  priceLabelStyle: PropTypes.object,
  listContentStyle: PropTypes.object,
};

PricingSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['100px', '100px', '100px', '140px', '160px'],
    pb: ['60px', '80px', '80px', '100px'],
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  secTitleWrapper: {
    mb: ['50px', '75px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#2a83a2',
    mb: '5px',
    textTransform: 'uppercase',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
    pr: '15px',
    pl: '15px',
  },
  nameStyle: {
    fontSize: ['20px', '20px', '22px', '22px', '22px'],
    fontWeight: '500',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    textAlign: 'center',
    mb: '12px',
  },
  descriptionStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  priceStyle: {
    as: 'span',
    display: 'block',
    fontSize: ['36px', '36px', '40px', '40px', '40px'],
    color: 'headingColor',
    textAlign: 'center',
    mb: '5px',
    letterSpacing: '-0.025em',
  },
  priceLabelStyle: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    bg: '#fff',
    color: '#2a83a2',
    colors: 'primaryWithBg',
    width: '222px',
    maxWidth: '100%',
  },
  buttonFillStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: 'white',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    colors: 'primaryWithBg',
    width: '200px',
    maxWidth: '100%',
  },
  listContentStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    mb: '0',
  },
};

export default PricingSection
