import Box from 'common/src/components/Box';
import Button from 'common/src/components/Button';
import Container from 'common/src/components/UI/Container';
import { DataContext } from 'common/src/contexts/DataContext'
import Heading from 'common/src/components/Heading';
import PartnerSectionWrapper from './partner.style';
import PrismicImage from 'common/src/components/PrismicImage';
import PropTypes from 'prop-types';
import React from 'react';
import { RichText } from 'prismic-reactjs';
import Text from 'common/src/components/Text';
import { graphql } from 'gatsby';

export const fragment = graphql`
  fragment PartnerContent on PRISMIC_Landing_page {
    info_section_title
    info_section_text
    info_section_image
    info_section_cta
    info_section_cta_link
  }
`

const PartnerSection = ({
  row,
  col,
  title,
  description,
  button,
  textArea,
  imageArea,
}) => {
  const { data } = React.useContext(DataContext)
  if (!data) return null;

  const ctaLink = RichText.asText(data.info_section_cta_link)
  const ctaText = RichText.asText(data.info_section_cta)
  return (
    <PartnerSectionWrapper>
      <Container>
        <Box {...row}>
          <Box {...col} {...imageArea}>
            <PrismicImage
              {...data.info_section_image}
              alt={RichText.asText(data.info_section_title)}
              width="auto"
              height="auto"
            />
          </Box>
          <Box {...col} {...textArea}>
            <Heading
              {...title}
              content={RichText.asText(data.info_section_title)}
            />
            <RichText
              as="div"
              render={data.info_section_text}
              Component={Text}
              {...description}
            />
            {ctaLink && ctaText ? (
              <Box>
                <a href={ctaLink}>
                  <Button {...button} title={ctaText} />
                </a>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Container>
    </PartnerSectionWrapper>
  );
};

PartnerSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
};

PartnerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: ['100%', '100%', '55%', '50%', '42%'],
  },
  imageArea: {
    width: ['100%', '100%', '45%', '50%', '58%'],
    mb: ['40px', '40px', '0', '0', '0'],
  },
  title: {
    fontSize: ['26px', '30px', '30px', '48px', '48px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '15px',
    lineHeight: '1.25',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'secondaryWithBg',
    minWidth: '150px',
  },
};

export default PartnerSection
