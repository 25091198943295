import {
  AuthorImage,
  AuthorInfo,
  TestimonialItem,
  TestimonialMeta,
  TestimonialSlideWrapper,
} from './testimonial.style';

import Box from 'common/src/components/Box';
import Container from 'common/src/components/UI/Container';
import { DataContext } from 'common/src/contexts/DataContext'
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import Heading from 'common/src/components/Heading';
import PrismicImage from 'common/src/components/PrismicImage';
import PropTypes from 'prop-types';
import React from 'react';
import { RichText } from 'prismic-reactjs';
import Text from 'common/src/components/Text';
import { graphql } from 'gatsby';

export const fragment = graphql`
  fragment TestimonialsContent on PRISMIC_Landing_page {
    reviews_title
    reviews_subtitle
    reviews {
      image
      name
      position
      profile_url
      text
      title
    }
  }
`

const TestimonialSection = ({
  sectionWrapper,
  secTitleWrapper,
  secText,
  secHeading,
  reviewTitle,
  review,
  name,
  designation,
}) => {
  const { data } = React.useContext(DataContext)
  if (!data) return null;

  const carouselOptions = {
    type: 'carousel',
    autoplay: 6000,
    perView: 2,
    gap: 30,
    animationDuration: 800,
    breakpoints: {
      990: {
        perView: 1,
      },
    },
  };

  return (
    <Box {...sectionWrapper} as="section" id="testimonials">
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content={RichText.asText(data.reviews_title)} />
          <Heading
            {...secHeading}
            content={RichText.asText(data.reviews_subtitle)}
          />
        </Box>
        <TestimonialSlideWrapper>
          <GlideCarousel
            options={carouselOptions}
            carouselSelector="testimonial__slider"
            controls={false}
            bullets={true}
            numberOfBullets={data.reviews.length}
          >
            <>
              {data.reviews.map((item, index) => {
                const url = item.profile_url
                  ? RichText.asText(item.profile_url)
                  : null;
                const authowInfo = (
                  <AuthorInfo>
                    {item.image ? (
                      <AuthorImage>
                        <PrismicImage url={item.image.url} alt={RichText.asText(item.name)} />
                      </AuthorImage>
                    ) : null}
                    <Box>
                      <Heading
                        as="h3"
                        content={RichText.asText(item.name)}
                        {...name}
                      />
                      <Text
                        content={RichText.asText(item.position)}
                        {...designation}
                      />
                    </Box>
                  </AuthorInfo>
                );

                return (
                  <GlideSlide key={`testimonial-slide-${index}`}>
                    <TestimonialItem>
                      <Heading
                        as="h3"
                        content={RichText.asText(item.title)}
                        {...reviewTitle}
                      />
                      <Text content={RichText.asText(item.text)} {...review} />
                      <TestimonialMeta>
                        {url ? (
                          <a href={url} target="_blank" rel="nofollow">
                            {authowInfo}
                          </a>
                        ) : (
                          authowInfo
                        )}
                      </TestimonialMeta>
                    </TestimonialItem>
                  </GlideSlide>
                );
              })}
            </>
          </GlideCarousel>
        </TestimonialSlideWrapper>
      </Container>
    </Box>
  );
};

TestimonialSection.propTypes = {
  sectionHeader: PropTypes.object,
};

TestimonialSection.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '80px', '90px', '100px', '100px'],
    pb: ['60px', '80px', '90px', '100px', '100px'],
  },
  secTitleWrapper: {
    mb: ['40px', '60px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#2a83a2',
    mb: '5px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
  reviewTitle: {
    fontSize: ['15px', '16px'],
    fontWeight: '500',
    color: '#343d48',
    lineHeight: '1.5',
    mb: '13px',
  },
  review: {
    fontSize: ['16px', '19px'],
    fontWeight: '300',
    color: '#343d48',
    lineHeight: '1.7',
    mb: 0,
  },
  name: {
    fontSize: ['14px', '16px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '8px',
  },
  designation: {
    fontSize: ['12px', '14px'],
    color: '#6f7a87',
    mb: 0,
  },
};

export default TestimonialSection
