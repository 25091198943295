import { LanguageContext } from 'common/src/contexts/LanguageContext'
import React from 'react';

const ru = {
  nameLabel: 'Ваше имя',
  emailLabel: 'Ваш email',
  serviceTermsPrefix: 'Принимаю',
  personalDataPrefix: 'Согласен(а) с',
  serviceTermsLink: 'условия предоставления услуг',
  personalDataLink: 'политикой обработки персональных данных',
  termsHref: '/terms-of-service',
  privacyHref: '/privacy-policy',
  mustAccept: 'Для отправки заявки необходимо принять условия.',
  buttonText: 'Отправить заявку',
  successText: 'Спасибо! Ваша заявка принята. Мы свяжемся с вами по указанному email в ближайшее время.'
}

const en = {
  nameLabel: 'Your name',
  emailLabel: 'Your email',
  serviceTermsPrefix: 'I accept',
  personalDataPrefix: 'I agree with',
  serviceTermsLink: 'terms of the service.',
  termsHref: '/en/terms-of-service',
  personalDataLink: 'privacy policy',
  privacyHref: '/en/privacy-policy',
  mustAccept: 'Please accept terms and privacy policy to send a request.',
  buttonText: 'Apply',
  successText: 'Thank you! You request has been sent. We will contact you soon.'
}

export const useContactFormData = () => {
  const { lang } = React.useContext(LanguageContext)

  if (lang === 'en') {
    return en
  }

  return ru
}
