import Box from 'common/src/components/Box';
import Container from 'common/src/components/UI/Container';
import { DataContext } from 'common/src/contexts/DataContext'
import FeatureBlock from 'common/src/components/FeatureBlock';
import Heading from 'common/src/components/Heading';
import PrismicImage from 'common/src/components/PrismicImage';
import PropTypes from 'prop-types';
import React from 'react';
import { RichText } from 'prismic-reactjs';
import Text from 'common/src/components/Text';
import { graphql } from 'gatsby';

export const fragment = graphql`
  fragment FeatureContent on PRISMIC_Landing_page {
    advantages_subtitle
    advantages_title
    advantages_items {
      image
      text
      title
    }
  }
`

const FeatureSection = ({
  sectionWrapper,
  secTitleWrapper,
  secText,
  secHeading,
  row,
  col,
  FeatureItemStyle,
  iconStyle,
  contentStyle,
  featureTitle,
  featureDescription,
}) => {
  const { data } = React.useContext(DataContext)
  if (!data) return null;

  return (
    <Box {...sectionWrapper} as="section" id="feature_section">
      <Container>
        <Box {...secTitleWrapper}>
          <Text
            {...secText}
            content={RichText.asText(data.advantages_title)}
            style={{ textTransform: 'uppercase' }}
          />
          <Heading
            {...secHeading}
            content={RichText.asText(data.advantages_subtitle)}
          />
        </Box>

        <Box {...row}>
          {data.advantages_items.map((item, index) => (
            <Box {...col} key={`feature-item-${index}`}>
              <FeatureBlock
                icon={
                  <PrismicImage
                    {...item.image}
                    alt={`${RichText.asText(item.title)}`}
                  />
                }
                wrapperStyle={FeatureItemStyle}
                iconStyle={iconStyle}
                contentStyle={contentStyle}
                iconPosition="left"
                title={
                  <Heading
                    content={RichText.asText(item.title)}
                    {...featureTitle}
                  />
                }
                description={
                  <RichText
                    as="div"
                    render={item.text}
                    Component={Text}
                    {...featureDescription}
                  />
                }
              />
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

FeatureSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secText: PropTypes.object,
  secHeading: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  FeatureItemStyle: PropTypes.object,
  iconStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
};

FeatureSection.defaultProps = {
  sectionWrapper: {
    pt: ['80px', '80px', '80px', '80px', '80px'],
    pb: ['20px', '50px', '60px', '70px', '100px'],
  },
  secTitleWrapper: {
    mb: ['60px', '100px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#2a83a2',
    mb: '5px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: ['-30px', '-30px', '-30px', '-30px', '-30px'],
    mr: ['-30px', '-30px', '-30px', '-30px', '-45px'],
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
    pl: ['30px', '30px', '30px', '30px', '45px'],
    pr: ['30px', '30px', '30px', '30px', '45px'],
    mb: ['50px', '70px'],
  },
  FeatureItemStyle: {
    alignItems: 'center',
  },
  iconStyle: {
    width: ['90px', '90px', '90px', '75px', '90px'],
    pr: '20px',
    pt: '45px',
  },
  featureTitle: {
    fontSize: ['18px', '20px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    lineHeight: '1.5',
    mb: ['10px', '15px'],
  },
  featureDescription: {
    fontSize: '15px',
    fontWeight: '400',
    color: '#5d646d',
    letterSpacing: '-0.025em',
    lineHeight: '1.88',
    mb: 0,
  },
};

export default FeatureSection
