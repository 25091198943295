import Box from 'common/src/components/Box';
import Button from 'common/src/components/Button';
import Container from 'common/src/components/UI/Container';
import { DataContext } from 'common/src/contexts/DataContext'
import DesktopImage from 'common/src/assets/image/lang4u/desktop.svg';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import PropTypes from 'prop-types';
import React from 'react';
import { RichText } from 'prismic-reactjs';
import Text from 'common/src/components/Text';
import { graphql } from 'gatsby';

export const fragment = graphql`
  fragment TrialContent on PRISMIC_Landing_page {
    promo_button_link
    promo_button_text
    promo_link_text
    promo_link_url
    promo_text
    promo_title
  }
`

const TrialSection = ({
  sectionWrapper,
  row,
  title,
  description,
  textArea,
  imageArea,
  ImageOne,
  btnStyle,
  outlineBtnStyle,
  buttonWrapper,
}) => {
  const { data } = React.useContext(DataContext)
  if (!data) return null;

  return (
    <Box {...sectionWrapper}>
      <Container>
        <Box {...row}>
          <Box {...imageArea}>
            <Image {...ImageOne} src={DesktopImage} alt={RichText.asText(data.promo_title)} width={128} />
          </Box>
          <Box {...textArea}>
            <Heading
              {...title}
              content={RichText.asText(data.promo_title)}
            />
            <Text
              {...description}
              content={RichText.asText(data.promo_text)}
            />
            <Box {...buttonWrapper}>
              <a href={RichText.asText(data.promo_button_link)}>
                <Button title={RichText.asText(data.promo_button_text)} {...btnStyle} />
              </a>
              <a href={RichText.asText(data.promo_link_url)}>
                <Button
                  title={RichText.asText(data.promo_link_text)}
                  variant="textButton"
                  {...outlineBtnStyle}
                />
              </a>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

TrialSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  imageArea: PropTypes.object,
  ImageOne: PropTypes.object,
};

TrialSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    className: 'trial-section',
    pt: ['20px', '40px', '60px', '80px'],
    pb: ['0px', '0px', '0px', '80px'],
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textArea: {
    width: ['100%', '100%', '80%', '43%'],
  },
  imageArea: {
    width: ['100%', '100%', '43%'],
    mb: ['35px', '35px', '40px', '40px'],
  },
  title: {
    fontSize: ['28px', '32px', '36px', '42px', '48px'],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '28px',
    textAlign: 'center',
    lineHeight: '1.25',
  },
  description: {
    fontSize: ['15px', '15px', '16px', '16px', '16px'],
    color: '#5c636c',
    lineHeight: '2.1',
    textAlign: 'center',
    mb: ['35px', '35px', '40px', '60px'],
  },
  ImageOne: {
    ml: 'auto',
    mr: 'auto',
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    colors: 'secondaryWithBg',
    pl: ['15px', '30px'],
    pr: ['15px', '30px'],
    mr: '15px',
  },
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#0f2137',
    colors: 'secondary',
  },
};

export default TrialSection
