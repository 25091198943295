import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { base, themed } from '../base';

const ImageWrapper = styled('img')(
  {
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
  },
  base,
  themed('Image')
);

const PrismicImage = ({ url, alt, dimensions, ...props }) => {
  const { width, height } = (dimensions || {})
  return (
    <ImageWrapper src={url} alt={alt} width={width} height={height} {...props} />
  )
}

export default PrismicImage;

PrismicImage.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

PrismicImage.defaultProps = {
  m: 0,
};
