import { BannerWrapper, DiscountLabel, DiscountWrapper } from './banner.style';

import Box from 'common/src/components/Box';
import Button from 'common/src/components/Button';
import Container from 'common/src/components/UI/Container';
import { DataContext } from 'common/src/contexts/DataContext'
import Fade from 'react-reveal/Fade';
import Heading from 'common/src/components/Heading';
import Icon from 'react-icons-kit';
import PrismicImage from 'common/src/components/PrismicImage';
import PropTypes from 'prop-types';
import React from 'react';
import { RichText } from 'prismic-reactjs';
import Text from 'common/src/components/Text';
import TiltShape from '../TiltShape';
import { graphql } from 'gatsby';
import { ic_play_circle_filled } from 'react-icons-kit/md/ic_play_circle_filled';

export const fragment = graphql`
  fragment BannerContent on PRISMIC_Landing_page {
    hero_promotion_highlighted_prefix
    hero_promotion_suffix
    hero_subtitle
    hero_title
    hero_image
    hero_button_text
    hero_button_link 
    hero_video_button_text
    heri_text_button_link
  }
`

const BannerSectionContent = ({
  row,
  contentWrapper,
  discountAmount,
  discountText,
  title,
  description,
  imageWrapper,
  buttonWrapper,
  button,
  fillButton,
  image,
}) => {
  const { data } = React.useContext(DataContext)
if (!data) return null;
  const heroButtonLink = RichText.asText(data.hero_button_link)
  const heroButtonText = RichText.asText(data.hero_button_text)

  const heroButtonVideoLink = RichText.asText(data.heri_text_button_link)
  const heroButtonVideoText = RichText.asText(data.hero_video_button_text)

  return (
    <BannerWrapper id="banner_section">
      <TiltShape />
      <Container>
        <Box {...row}>
          <Box {...contentWrapper}>
            <DiscountWrapper>
              <DiscountLabel>
                <Text
                  {...discountAmount}
                  content={RichText.asText(data.hero_promotion_highlighted_prefix)}
                />
                <Text
                  {...discountText}
                  content={RichText.asText(data.hero_promotion_suffix)}
                />
              </DiscountLabel>
            </DiscountWrapper>
            <Heading
              {...title}
              content={RichText.asText(data.hero_title)}
            />
            <Text
              {...description}
              content={RichText.asText(data.hero_subtitle)}
            />
            <Box {...buttonWrapper}>
              {heroButtonLink && heroButtonText ? (
                <a href={heroButtonLink}>
                  <Button {...fillButton} title={heroButtonText} />
                </a>
              ) : null}
              {heroButtonVideoLink && heroButtonVideoText ? (
                <a href={heroButtonVideoLink}>
                  <Button
                    {...button}
                    title={heroButtonVideoText}
                    icon={<Icon icon={ic_play_circle_filled} size={30} />}
                    iconPosition="left"
                    style={{whiteSpace: 'nowrap'}}
                  />
                </a>
              ) : null}
            </Box>
          </Box>
          <Box {...imageWrapper}>
            <Fade bottom>
              <PrismicImage {...data.hero_image} {...image} />
            </Fade>
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSectionContent.propTypes = {
  row: PropTypes.object,
  contentWrapper: PropTypes.object,
  discountAmount: PropTypes.object,
  discountText: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  imageWrapper: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
  fillButton: PropTypes.object,
};

BannerSectionContent.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentWrapper: {
    width: ['100%', '100%', '80%', '55%', '50%'],
    mb: '40px',
  },
  imageWrapper: {
    width: '100%',
  },
  title: {
    fontSize: ['24px', '32px', '40px', '42px', '46px'],
    fontWeight: '700',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px', '25px', '25px', '25px'],
    lineHeight: '1.2',
    textAlign: 'center',
  },
  description: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: '#fff',
    lineHeight: '1.75',
    mb: '0',
    textAlign: 'center',
  },
  discountAmount: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    fontWeight: '700',
    color: '#00865b',
    mb: 0,
    as: 'span',
    mr: '0.4em',
  },
  discountText: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    fontWeight: '700',
    color: '#fff',
    mb: 0,
    as: 'span',
  },
  fillButton: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    colors: 'secondaryWithBg',
    minWidth: ['auto', '150px'],
    height: ['40px', '46px'],
    minHeight: 'auto',
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
    mt: '35px',
  },
  button: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    color: '#fff',
    colors: 'secondary',
    height: ['40px', '46px'],
    minHeight: 'auto',
  },
  image: {
    borderRadius: '20px'
  }
};

export default BannerSectionContent
